<template>
    <div class="contactUs">
        <div class="section-wrap">
            <p class="inner1">闪信是西安弘鹰实业有限公司投资研发、全额持有、拥有著作权、面向大众的，不断进化改善中的一款软件。</p>
            <p class="inner1 inner2">我们诚新的愿意聆听诸位客户的各种意见和建议，能够收到您的致电、信件、电邮、信息等是我们的荣幸，我们将认真的聆听您的表述，并全力采取有效的措施响应您的所述，但受法规政策、技术手段、行业惯例、公序良俗之要求等限制，不能达到您之要求的，请您予以谅解。</p>
            <div class="box1">
                一、致电您请拨打如下电话
            </div>
            <div class="box2">
                服务电话:029-88220476
            </div>
            <div class="box2">
                服务电话：18066536339
            </div>
            <div class="box2">
                紧急电话：
            </div>
            <div class="box1">
                二、信件您请邮递至如下地址
            </div>
            <div class="box2">
                邮递地址：陕西省西安市雁塔区太白南路紫薇尚层西5栋903室
            </div>
            <div class="box2">
                邮    编：710065
            </div>
            <div class="box2">
                收 件 人：西安弘鹰实业有限公司
            </div>
            <div class="box2">
                电    话：18066535339
            </div>
            <div class="box1">
                三、闪信请您联系如下平台客服
            </div>
            <div class="box2">
                闪信平台客服1：
            </div>
            <div class="box2">
                闪信平台客服2：
            </div>
            <div class="box2">
                紧急平台客服：
            </div>
            
            <div class="box1">
                四、电邮请您发送至如下地址
            </div>
            <div class="box2">
                电邮地址1：shanxinlife@163.com
            </div>
            <div class="box2">
                电邮地址2：shanxintong@163.com
            </div>
            <div class="box2">
                收件人：西安弘鹰实业有限公司
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "contactUs",
    components: {},
    data() {
        return {};
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
.contactUs {
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-wrap {
        width: 12rem;
        padding-top: 0.6rem;
        font-size: 0.2rem;
        color: #4c4c4c;
        font-weight: 500;
        line-height: 0.4rem;
        text-indent: 0.8rem;

        .box1 {
            font-size: 0.2rem;
            color: #131313;
            font-weight: 600;
            display: block;
            margin-bottom: 0.15rem;
        }
        .box2{
            font-size: 0.18rem;
            color: #000;
            margin-bottom: 0.05rem;
        }
        .inner2{
            margin: 0.2rem 0;
        }
    }
}
</style>
  